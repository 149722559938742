import { GoToNextStepRequest } from '~/types/GoToNextStepRequest';
import { RentalApplicationStep } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '../magicUseCase';
import { SaveRentalApplicationUseCase } from './application-steps/saveRentalApplicationUseCase';

export class GoToNextStepUseCase extends MagicUseCase {
  protected async runLogic(request: GoToNextStepRequest) {
    const application = this.getState().user.rentalApplication.application;
    if (application && !application.isDraft && !this.isPaid()) {
      this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/payment`);
      return;
    }
    if (application?.isDraft) {
      await new SaveRentalApplicationUseCase().execute(request.credentials);
    }
    switch (request.currentStep) {
      case RentalApplicationStep.ApplicantInfo: {
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/potential-units`);
        break;
      }
      case RentalApplicationStep.PotentialUnits: {
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/rental-history`);
        break;
      }
      case RentalApplicationStep.RentalHistory: {
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/employment-income`);
        break;
      }
      case RentalApplicationStep.IncomeVerification: {
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/rental-questions`);
        break;
      }
      case RentalApplicationStep.Questions: {
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/terms-and-conditions`);
        break;
      }
      case RentalApplicationStep.TermsAndConditions: {
        if (!this.isPaid()) {
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/payment`);
        } else {
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/transunion-connection`);
        }
        break;
      }
    }
  }

  private isPaid(): boolean {
    return this.getState()?.user.rentalApplication.isPaid || !this.getState()?.user.rentalApplication.application?.requiresPayment;
  }
}
